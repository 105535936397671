import React from 'react'
import Layout from '../layouts'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import buyingBG from '../images/selling-bg.jpg'
// import CommonHero from '../components/commonhero/commonhero.js'

class SellingProcessPage extends React.Component {
  render() {
    return (
      <Layout
        head={{
          title: 'The Selling Process',
        }}
      >
        <div className="page-header">
          <div
            className="page-banner buying"
            style={{
              width: `100%`,
              height: `100%`,
              background: `url(${buyingBG})`,
              top: '12%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-6">
                <div className="hero-content align-self-center">
                  <h1 className="text-white align-self-center">
                    The Selling Process
                  </h1>
                  <p className="text-white">
                    Give Brian a call at{' '}
                    <a href="tel:7194599955" className="text-white">
                      (719) 459-9955
                    </a>
                      to start on your custom marketing analysis and marketing
                    plan today!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <main className="bg-white">
          <div className="container py-5">
            <div class="intro text-center my-5">
              <h2 className="text-black">Looking to sell a home?</h2>
              <h6 class="subtitle text-black">
                Here is the general process for selling your home:
              </h6>
            </div>
            <div className="buying-item bg-light-blue mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">1</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Market Analysis</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          5-15 minute property preview to gather information and
                          the upgrades and unique aspects of the home
                        </li>
                        <li>
                          Research and put together a comprehensive market
                          analysis that will go over properties that have sold
                          in the last 3-6 months to validate a solid market
                          price for your property. Market analysis will also
                          include the pending and under contract homes to
                          understand what buyers see as value; as well as active
                          listing competition in your area.
                        </li>
                        <li>
                          Set up a meeting in-person or via Zoom to go over your
                          custom comparative market analysis and custom
                          marketing ideas and strategies tog et top dollar for
                          your home.
                        </li>
                        <li>
                          Once the listing price is set and you know when you
                          would like to put your property on the market, we will
                          go over listing documents and disclosures with you.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buying-item mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">2</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Listing the Property</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          The house will need a deep clean to prepare it for the
                          best photo and showing results.
                        </li>
                        <li>
                          We will coordinate scheduling professional staging and
                          professional photography, as well as put a lockbox on
                          your property.
                        </li>
                        <li>
                          Your property can be marketed in as little as 48 hours
                          from photos being taken, and will be marketed in Pikes
                          Peak MLS, Denver MLS, and several websites, as well as
                          many other approaches to marketing and spreading the
                          word about your property!
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buying-item bg-light-blue mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">3</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Property Showings</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          Realtors will call our office to schedule showings on
                          your property and our office will contact you based on
                          your preferences (email, call, text, Showingtime app,
                          or all 4!).
                        </li>
                        <li>
                          We will send feedback on the showings and also provide
                          you with weekly updates.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buying-item mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">4</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Getting an Offer</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          Once an offer is received, we will speak with the
                          agent and lender, and send the offer and lender letter
                          to review and discuss. You can choose to accept the
                          offer, counter, or reject.
                        </li>
                        <li>
                          Once an offer is accepted and you are under contract,
                          the buyer will deposit the Earnest Money Deposit with
                          the title company.
                        </li>
                        <li>
                          A Title order will be placed to open your new contract
                          with the title company, and they will provide a title
                          commitment on your property. This will show if there
                          are any liens under your names that will need to be
                          cleared at closing in order to deliver clear title and
                          deed to the buyer.
                        </li>
                        <li>
                          The buyer will complete any inspections of their
                          choosing during the inspection period outlined in the
                          contract (usually a 7-10 day period from the
                          acceptance date). Typically, a buyer will have a
                          licensed home inspector do a comprehensive inspection
                          on your property, and oftentimes complete additional
                          testing such as radon, sewer scope, mold, etc.
                        </li>
                        <li>
                          Buyer will send an Inspection Objection of any items
                          they are requesting that you complete prior to
                          closing. Buyers generally ask for health and safety
                          items to be repaired, if any.
                        </li>
                        <li>
                          You will respond with what repairs you are willing to
                          make, if any, and if all parties are agreeable, you
                          will sign an Inspection Resolution.
                        </li>
                        <li>
                          If you have a septic system, you will contact a county
                          approved septic inspection company to pump and inspect
                          the septic system and apply for a Septic Acceptance
                          Document from El Paso County in order to transfer the
                          system to the buyer at closing.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buying-item bg-light-blue mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">5</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Appraisal</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          If the buyer is getting a loan, an appraisal will most
                          likely be required, which will be ordered by the
                          lender. I will meet with the appraiser at your
                          property to discuss features of the house and go over
                          the best possible comparables in your area to justify
                          the agreed upon contract price.
                        </li>
                        <li>
                          Once the appraisal is in and the closing date is
                          approaching, we will coordinate a closing time that
                          will work for you and the buyers. The buyer will want
                          to complete a walk through of the house prior to
                          closing, so it’s best to be moved out with all
                          cleaning done the day prior to closing
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>{' '}
            <div className="buying-item mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">6</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Pre-closing</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          You will be provided with the settlement statement to
                          show the breakdown of your closing costs and your net
                          cash 1-3 days prior to the closing date.
                        </li>
                        <li>
                          We will obtain paid receipts from you for any
                          inspection work, to provide to the buyer.
                        </li>
                        <li>
                          Finally off to closing! This could take 30 minutes to
                          an hour depending on if it is a cash or loan purchase
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buying-item bg-light-blue mb-5">
              <div className="row align-items-start">
                <div className="col-md-3 col-xl-2 mb-4 mb-md-0">
                  <h5 className="buying-number">7</h5>
                </div>
                <div className="col-md-9 col-xl-10">
                  <div className="buying-info">
                    <h4 className="mb-3">Closing</h4>
                    <div className="info-list">
                      <ul>
                        <li>
                          Once everyone has signed the closing documents and the
                          loan has funded, the title company will wire your
                          proceeds to your account. Funds may be in your account
                          as early as the same day if everything is wrapped up
                          and wired before 2:00 PM!
                        </li>
                        <li>
                          You will be provided with copies of the signed closing
                          documents, so be sure to keep those for tax purposes.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>{' '}
          </div>
        </main>
      </Layout>
    )
  }
}

export default SellingProcessPage
